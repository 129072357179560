import axios from "axios";
import {AuthContextProps} from "react-oidc-context";
import { toast } from "react-toastify";
import { StudentInfo } from "../interfaces/StudentInfo";

export class UserService {
    static async GetStudentInfoByLogin(auth: AuthContextProps, studentLogin: String): Promise<StudentInfo> {
        try {
            const response = await axios.get<StudentInfo>(`${process.env.REACT_APP_SCOLARITE_API_URL}/student/${studentLogin}`, { headers: { Authorization: `Bearer ${auth.user?.access_token}` } });
            return response.data;
        } catch (error) {
            toast.error("Aucun utilisateur trouvé avec cet identifiant.");
            throw error;
        }
    }

    static async UpdateStudentAttributes(auth: AuthContextProps, studentLogin: String, studentInfo: FormData): Promise<StudentInfo> {
        try {
            const response = await axios.put<StudentInfo>(
                `${process.env.REACT_APP_SCOLARITE_API_URL}/student/${studentLogin}`,
                studentInfo,
                { 
                    headers: { 
                        Authorization: `Bearer ${auth.user?.access_token}`,
                        "Content-Type": "multipart/form-data" // Indique qu'on envoie un fichier
                    } 
                }
            );
            return response.data;
        } catch (error) {
            toast.error("Erreur lors de la modification des données personnelles.");
            throw error;
        }
    }
}
