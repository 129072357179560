import axios from "axios";
import React, { useEffect, useState } from "react";
import { useAuth } from "react-oidc-context";
import { AbsenceCount, Declaration } from "../../../interfaces/AbsenceInterface";
import { PageTab } from "../../../pages/Absences";
import Select from "react-select";
import { toast } from "react-toastify";

function Selector(props: { 
    setStudentSelected: (arg0: string) => void;
    tabs: string;
    promotion: string;
    setPromotion: (arg0: string) => void;
}) {
    interface selectorData {
        student: string;
        count: number;
    }

    const [students, setStudents] = useState<selectorData[]>([]);
    const auth = useAuth();

    const date = new Date();
    const year = date.getFullYear();
    // as we want to be syncrhronized with the api, the promotion will change the 1st of February
    const lastPromo = date.getMonth() < 1 ? year: year + 1;
    
    function sorting(elements: selectorData[]) : selectorData[] {
        return elements.sort((a, b) => b.count - a.count);
    }

    const setUsersAbsence = async (promotion: string) => {
        let studentList: selectorData[] = [];
        if (promotion === '') {
            await axios.get<AbsenceCount[]>(`${process.env.REACT_APP_ABSENCE_API_URL}/absences/count`,
                { 
                    headers: { 
                        Authorization: `Bearer ${auth.user?.access_token}`,
                        promotion: lastPromo
                    }
                })
                .then(response => {
                    studentList = response.data.map(absence => ({ student: absence.studentLogin, count: absence.count }));
                }, error => {
                    toast.error("Une erreur est survenue");
                }
                );
            await axios.get<AbsenceCount[]>(`${process.env.REACT_APP_ABSENCE_API_URL}/absences/count`,
                { 
                    headers: { 
                        Authorization: `Bearer ${auth.user?.access_token}`,
                        promotion: lastPromo - 1
                    }
                })
                .then(response => {
                    studentList = studentList.concat(response.data.map(absence => ({ student: absence.studentLogin, count: absence.count })));
                }, error => {
                    toast.error("Une erreur est survenue");
                }
                );
            setStudents(sorting(studentList));
            return;
        } 
        await axios.get<AbsenceCount[]>(`${process.env.REACT_APP_ABSENCE_API_URL}/absences/count`,
            { 
                headers: { 
                    Authorization: `Bearer ${auth.user?.access_token}`,
                    promotion: parseInt(promotion)
                }
            })
            .then(response => {
                setStudents(response.data.map(absence => ({ student: absence.studentLogin, count: absence.count })));
            }, error => {
                toast.error("Une erreur est survenue");
            }
            );
        return;
        
    };

    const setDeclarationsToFormat = (declarations: Declaration[]): selectorData[] => {
        const studentDeclarationCounts: { [student: string]: number } = {};
    
        declarations.forEach(declaration => {
            if (studentDeclarationCounts[declaration.studentLogin]) {
                studentDeclarationCounts[declaration.studentLogin] += 1;
            } else {
                studentDeclarationCounts[declaration.studentLogin] = 1;
            }
        });
    
        const result: selectorData[] = Object.keys(studentDeclarationCounts).map(student => ({
            student,
            count: studentDeclarationCounts[student]
        }));
    
        return result;
    };
    
    const setUsersDeclaration = async (promotion: string) => {
        let studentList: selectorData[] = [];
        if (promotion === '') {
            await axios.get<Declaration[]>(`${process.env.REACT_APP_ABSENCE_API_URL}/declarations/${lastPromo}`,
                { 
                    headers: { 
                        Authorization: `Bearer ${auth.user?.access_token}`
                    }
                })
                .then(response => {
                    studentList = setDeclarationsToFormat(response.data);
                }, error => {
                    toast.error("Une erreur est survenue");
                }
                );
            await axios.get<Declaration[]>(`${process.env.REACT_APP_ABSENCE_API_URL}/declarations/${lastPromo - 1}`,
                { 
                    headers: { 
                        Authorization: `Bearer ${auth.user?.access_token}`
                    }
                })
                .then(response => {
                    studentList = studentList.concat(setDeclarationsToFormat(response.data));
                }, error => {
                    toast.error("Une erreur est survenue");
                }
                );
            setStudents(sorting(studentList));
            return;
        } 
        await axios.get<Declaration[]>(`${process.env.REACT_APP_ABSENCE_API_URL}/declarations/${promotion}`,
            { 
                headers: { 
                    Authorization: `Bearer ${auth.user?.access_token}`,
                    promotion: parseInt(promotion)
                }
            })
            .then(response => {
                setStudents(sorting(setDeclarationsToFormat(response.data)));
            }, error => {
                toast.error("Une erreur est survenue");
            }
            );
        return;
        
    };

    useEffect(() => {
        if (props.tabs === PageTab.Absence) {
            setUsersAbsence(props.promotion);
        }
        else {
            setUsersDeclaration(props.promotion);
        }
    }, [auth.user?.access_token, props.tabs, props.promotion]);

    return (
        <div className="d-flex">
            <Select
                name="promoSelector"
                className="w-25 mt-2 me-2"
                onChange={(selectedPromotion) => props.setPromotion(selectedPromotion?.value || "")}
                defaultValue={{ label: "Toutes les promotions", value: "" }}
                options ={[{label : "Toutes les promotions", value : ""}, {label : lastPromo.toString(), value : lastPromo.toString()}, {label : (lastPromo - 1).toString(), value : (lastPromo - 1).toString()}]}
            />
            <Select
                name="absenceSelector"
                className="w-25 mt-2"
                onChange={(selectedStudent) => props.setStudentSelected(selectedStudent?.value || "")}
                defaultValue={{ label: "Tous les étudiants", value: "" }}
                options ={[{label : "Tous les étudiants", value : ""},...students.map((student) => ({ value: student.student, label: student.student + " (" + student.count + ")" })) ]}
            />
        </div>
    );
    
    
}

export default Selector;
