import React from "react";
import { FaLinkedin } from "react-icons/fa";
import { Student } from "./StudentInterface";

export default function TableRow({
    student,
    optionColumns,
}: {
  student: Student;
  optionColumns: any;
}) {
    return (
        <tr>
            {optionColumns.name && (
                <td>
                    <div className="d-flex align-items-center">
                        {student?.profilePicture &&
              student.profilePicture.length > 100 ? (
                                <img
                                    src={`data:image/jpeg;base64,${student.profilePicture}`}
                                    style={{
                                        height: "40px",
                                        objectFit: "cover",
                                        width: "40px",
                                        borderRadius: "50%",
                                    }}
                                    alt="profile"
                                    className="rounded-circle me-2 "
                                    onError={(e) => {
                                        e.currentTarget.src = `https://ui-avatars.com/api/?name=${
                                            student?.firstName + " " + student?.lastName
                                        }&background=0D8ABC&color=fff`;
                                    }}
                                />
                            ) : (
                                <img
                                    src={`https://ui-avatars.com/api/?name=${
                                        student?.firstName + " " + student?.lastName
                                    }&background=0D8ABC&color=fff`}
                                    style={{
                                        height: "40px",
                                        objectFit: "cover",
                                        width: "40px",
                                        borderRadius: "50%",
                                    }}
                                    alt="profile"
                                    className="rounded-circle me-2"
                                />
                            )}
                        <div>
                            <strong>{student.firstName + " " + student.lastName} </strong>
                            <br />
                            {student.email ? (
                                student.email
                            ) : (
                                <span className="badge bg-secondary">Non renseigné</span>
                            )}
                        </div>
                    </div>
                </td>
            )}
            {optionColumns.phone && (
                <td className="text-center align-middle">
                    {student.phoneNumber ? (
                        <span className="badge bg-dark">{student.phoneNumber}</span>
                    ) : (
                        <span className="badge bg-secondary">Non renseigné</span>
                    )}
                </td>
            )}
            {optionColumns.email && (
                <td className="text-center align-middle">
                    {student.email ? (
                        <span className="badge bg-dark">{student.email}</span>
                    ) : (
                        <span className="badge bg-secondary">Non renseigné</span>
                    )}
                </td>
            )}
            {optionColumns.group && (
                <td className="text-center align-middle">
                    <span className="badge bg-success">{student.group}</span>
                </td>
            )}
            {optionColumns.groupName && (
                <td className="text-center align-middle">
                    <span className="badge bg-success">{student.groupName}</span>
                </td>
            )}
            {optionColumns.pae && (
                <td className="text-left align-middle">
                    {student.groupPAE ? (
                        <span className="badge bg-info">{student.groupPAE}</span>
                    ) : (
                        <span className="badge bg-secondary">Non renseigné</span>
                    )}
                </td>
            )}
            {optionColumns.cec && (
                <td className="text-center align-middle">
                    {student.groupCEC ? (
                        <span className="badge bg-secondary">{student.groupCEC}</span>
                    ) : (
                        <span className="badge bg-secondary">Non renseigné</span>
                    )}
                </td>
            )}
            {optionColumns.international && (
                <td className="text-center align-middle">
                    {student.abroadSemester === "must" ? (
                        <span className="badge bg-success">doit partir</span>
                    ) : student.abroadSemester === "want" ? (
                        <span className="badge bg-info">veut partir</span>
                    ) : student.abroadSemester === "wont" ? (
                        <span className="badge bg-danger">ne veut pas partir</span>
                    ) : (
                        <span className="badge bg-secondary">Non renseigné</span>
                    )}
                </td>
            )}
            {optionColumns.linkedin && (
                <td className="text-center align-middle">
                    {student.linkedin ? (
                        <a href={student.linkedin}>
                            <FaLinkedin size={28} />
                        </a>
                    ) : (
                        <span className="badge bg-secondary">Non renseigné</span>
                    )}
                </td>
            )}
        </tr>
    );
}
