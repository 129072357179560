import React, { useEffect, useState } from "react";
import { useAuth } from "react-oidc-context";
import tickImg from "../../../../assets/icons/tick.svg";
import openAttendanceImg from "../../../../assets/icons/openAttendance.svg";
import detailAttendanceImg from "../../../../assets/icons/detailAttendance.svg";
import axios from "axios";
import { Button } from "react-bootstrap";
import { Group } from "../../../../interfaces/AuthentificationInterface";
import OpenAttendanceModal from "./openAttendanceModal";
import DetailsAttendanceModal from "./detailsAttendanceModal";
import WarningModal from "./WarningModal";
import { toast } from "react-toastify";

export interface PresenceState {
  course?: string;
  date?: Date;
  attendance: boolean;
  isPresent?: boolean;
}

function Presence() {
    const [presence, setPresence] = useState<PresenceState>({
        attendance: false,
    });
    const [isPresent, setIsPresent] = useState<boolean>(false);
    const [timeLeftForAttendance, setTimeLeftForAttendance] = useState<number>(0);
    const [attendanceStatus, setAttendanceStatus] = useState<string>("");
    const [showWarning, setShowWarning] = useState<boolean>(false);
    const [isCheckPresenceOpen, setIsCheckPresenceOpen] =
    useState<boolean>(false);

    // vars for warning modal
    const warningPrimaryText = "Check présence";
    const warningSecondaryText =
    "Toute déclaration frauduleuse entrainera une sanction disciplinaire.";
    const warningButtonText = "Confirmer";

    // Used to display the modals
    // Values: "Open" | "Details" | "null"
    const [modalType, setModalType] = useState<string | null>(null);

    // Auth
    const auth = useAuth();
    const userGroup = auth.user?.profile.group as Array<Group>;

    // Get if an attendance is open and if the user is present
    useEffect(() => {
        axios
            .get(
                `${process.env.REACT_APP_ABSENCE_API_URL}/attendance/get-attendance-record/`,
                {
                    headers: {
                        Authorization: `Bearer ${auth.user?.access_token}`,
                    },
                }
            )
            .then((response) => {
                setPresence(response.data);
                setIsPresent(response.data.isPresent ?? false);
            })
            .catch((error) => {
                console.error(error);
                toast.error("Une erreur est survenue");
            });
        if (userGroup.includes(Group.Representative)) {
            axios
                .get(
                    `${process.env.REACT_APP_ABSENCE_API_URL}/attendance/get-all-attendance`,
                    {
                        headers: {
                            Authorization: `Bearer ${auth.user?.access_token}`,
                        },
                    }
                )
                .then((_) => {
                    setIsCheckPresenceOpen(true);
                })
                .catch((error) => {
                    if (error.response.status == 404) {
                        if (
                            error.response.data.err.errorText == "No attendance record found"
                        ) {
                            setIsCheckPresenceOpen(false);
                            console.log("No attendance record found");
                            return;
                        }
                    }
                    console.error(error);
                    toast.error("Une erreur est survenue");
                });
        }
    }, [isPresent, auth.user]);

    // Get the time left before the attendance closes
    useEffect(() => {
        const intervalId = setInterval(() => {
            const presenceDate = new Date(presence.date ?? "");
            const parisTimeOffset = new Date().toLocaleString("en-US", {
                timeZone: "Europe/Paris",
            });
            const currentTime = new Date(parisTimeOffset);

            // 5 minutes = 300 000 milliseconds
            // 1 minute = 60 000 milliseconds
            const timeDifference =
        presenceDate.getTime() + 300000 - currentTime.getTime();

            if (timeDifference > 0) {
                setTimeLeftForAttendance(timeDifference);
            } else {
                setTimeLeftForAttendance(0);
            }

            if (timeDifference > 0) {
                const minutes = Math.floor(timeDifference / 60000);
                const seconds = Math.floor((timeDifference % 60000) / 1000);
                setAttendanceStatus(
                    `Fermeture dans ${minutes}:${seconds < 10 ? `0${seconds}` : seconds}`
                );
            } else {
                setAttendanceStatus("Présence fermée");
            }
        }, 1000);
        return () => clearInterval(intervalId);
    }, [presence.date]);

    // Set the user as present
    function handlePresence() {
        axios
            .post(
                `${process.env.REACT_APP_ABSENCE_API_URL}/attendance/record-attendance`,
                {},
                { headers: { Authorization: `Bearer ${auth.user?.access_token}` } }
            )
            .then((response) => {
                setIsPresent(true);
                toast.success("Ta présence à été validée");
            })
            .catch((error) => {
                console.error(error);
                toast.error("Une erreur est survenue");
            });
    }

    return (
        <>
            <div className="card">
                <div className="card-body d-flex flex-column">
                    <div className="justify-content-between d-flex">
                        <h3 className="card-title fw-bold">Présence</h3>
                        {userGroup.includes(Group.Representative) && (
                            <>
                                <Button
                                    variant="light"
                                    onClick={() =>
                                        isCheckPresenceOpen
                                            ? setModalType("Details")
                                            : setModalType("Open")
                                    }
                                >
                                    <img
                                        src={`${
                                            isCheckPresenceOpen
                                                ? detailAttendanceImg
                                                : openAttendanceImg
                                        }`}
                                        width="25"
                                        height="25"
                                        alt="Présence"
                                    />
                                </Button>
                            </>
                        )}
                    </div>
                    {presence.attendance || isCheckPresenceOpen ? (
                        <div className="py-2 mt-2">
                            <div className="col-3 fs-5 fw-semibold">{presence.course}</div>
                            <div className="justify-content-between d-flex mt-2">
                                <div className="text-secondary">{attendanceStatus}</div>
                                <Button
                                    className={`p-0 ${
                                        timeLeftForAttendance > 0 && presence.isPresent === false
                                            ? "active"
                                            : "disabled"
                                    } ${presence.isPresent ? "bg-secondary" : "bg-primary"}`}
                                    onClick={() => setShowWarning(true)}
                                >
                                    <img src={tickImg} alt="tick" width="30" height="30" />
                                </Button>
                            </div>
                        </div>
                    ) : (
                        <div className="card-text text-center text-secondary py-3">
              Pas de présence en cours
                        </div>
                    )}
                </div>
            </div>

            <OpenAttendanceModal
                setModalType={setModalType}
                setIsPresent={setIsPresent}
                show={modalType === "Open"}
                onHide={() => setModalType(null)}
            />
            <DetailsAttendanceModal
                modalType={modalType}
                setModalType={setModalType}
                setPresence={setPresence}
                show={modalType === "Details"}
                onHide={() => setModalType(null)}
                setIsCheckPresenceOpen={(bool) => setIsCheckPresenceOpen(bool)}
            />
            <WarningModal
                show={showWarning}
                onHide={() => setShowWarning(!showWarning)}
                primaryText={warningPrimaryText}
                secondaryText={warningSecondaryText}
                buttonText={warningButtonText}
                onClickButton={() => {
                    handlePresence();
                    setShowWarning(false);
                }}
            />
        </>
    );
}

export default Presence;
