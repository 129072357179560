import React, { useState } from 'react';
import { useAuth } from "react-oidc-context";
import { toast } from 'react-toastify';
import { StudentInfo } from '../../../../interfaces/StudentInfo';
import { UserService } from '../../../../services/UserService';

function ChangeUserInfoForm({ studentInfo }: { studentInfo: StudentInfo }) {
    const auth = useAuth();
    const [isLoading, setIsLoading] = useState(false);
    const [formData, setFormData] = useState<{
        [key: string]: any;
    }>({
        groupNames: studentInfo?.groupName ?? '',
        linkedin: studentInfo?.linkedin ?? '',
        phoneNumber: studentInfo?.phoneNumber ?? '',
        abroadSemester: studentInfo?.abroadSemester ?? 'want',
        profilePicture: '',
    });

    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setFormData({ ...formData, [event.target.name]: event.target.value });
    };

    const handleSelectChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        setFormData({ ...formData, [event.target.name]: event.target.value });
    };

    const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files?.[0];
    
        if (file) {
            const allowedTypes = ['image/png', 'image/jpeg', 'image/jpg'];
            if (!allowedTypes.includes(file.type)) {
                toast.error('Veuillez sélectionner une image au format PNG, JPG ou JPEG.');
                return;
            }
    
            // Vérifier la taille du fichier (max 3 Mo)
            const maxSizeInMB = 3;
            const maxSizeInBytes = maxSizeInMB * 1024 * 1024;
            if (file.size > maxSizeInBytes) {
                toast.error(`L'image ne doit pas dépasser ${maxSizeInMB} Mo.`);
                return;
            }
    
            // Lire le fichier si tout est OK
            const reader = new FileReader();
            reader.onloadend = () => {
                setFormData({ ...formData, profilePicture: file });
            };
            reader.readAsDataURL(file);
        }
    };    

    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
    
        if (formData.linkedin === '' || formData.phoneNumber === '' || formData.abroadSemester === '') {
            toast.error('Les champs LinkedIn et Numéro de téléphone doivent être remplis');
            return;
        } else if (!formData.linkedin.startsWith('https://www.linkedin.com/')) {
            toast.error('Le lien LinkedIn doit commencer par https://www.linkedin.com/');
            return;
        } else if (!formData.phoneNumber.match(/^[0-9]{10}$/)) {
            toast.error('Le numéro de téléphone doit être composé de 10 chiffres');
            return;
        }
    
        try {
            const formDataToSend = new FormData();
            formDataToSend.append("groupName", formData.groupNames ?? '');
            formDataToSend.append("linkedin", formData.linkedin);
            formDataToSend.append("phoneNumber", formData.phoneNumber);
            formDataToSend.append("abroadSemester", formData.abroadSemester);
            if (formData.profilePicture && typeof formData.profilePicture !== 'string') {
                formDataToSend.append("profilePicture", formData.profilePicture);
            }
            setIsLoading(true);
            await UserService.UpdateStudentAttributes(auth, studentInfo.login || '', formDataToSend);
            setIsLoading(false);
            toast.success('Informations modifiées avec succès');
            window.location.reload();
        } catch (error) {
            toast.error('Erreur lors de la modification de vos informations');
        }
    };
    

    const formNumberInputFields = [
        { name: 'groupNames', label: 'Nom du groupe', type: 'text' },
        { name: 'linkedin', label: 'LinkedIn', type: 'text' },
        { name: 'phoneNumber', label: 'Numéro de téléphone', type: 'text' }
    ];

    return (
        <>
            <form className="w-100" onSubmit={handleSubmit}>
                {formNumberInputFields.map((field) => (
                    <div className="form-group mb-2" key={field.name}>
                        <label htmlFor={field.name}>{field.label}</label>
                        <input
                            type={field.type}
                            className="form-control"
                            id={field.name}
                            name={field.name}
                            value={formData[field.name]}
                            onChange={handleInputChange} />
                    </div>
                ))}
                <div className="form-group mb-2">
                    <label htmlFor="abroadSemester">Semestre à l&apos;étranger</label>
                    <br />
                    <select
                        id="abroadSemester"
                        name="abroadSemester"
                        value={formData.abroadSemester}
                        onChange={handleSelectChange}
                    >
                        <option value="want">Je veux partir à l&apos;étranger</option>
                        <option value="wont">Je ne veux pas partir à l&apos;étranger</option>
                        <option value="must">Je dois partir à l&apos;étranger</option>
                    </select>
                </div>
                <div className="form-group mb-2">
                    <label htmlFor="profilePicture">Photo de profil</label>
                    <input
                        type="file"
                        className="form-control"
                        id="profilePicture"
                        name="profilePicture"
                        accept="image/*"
                        onChange={handleFileChange}
                    />
                </div>
                <br />
                <button type="submit" className="btn btn-primary" disabled={isLoading}>
                    {isLoading ? (
                        <>
                            <span className="spinner-border spinner-border-sm me-2" role="status" aria-hidden="true"></span>
                        Chargement...
                        </>
                    ) : (
                        "Soumettre"
                    )}
                </button>
            </form>
        </>
    );
}

export default ChangeUserInfoForm;
