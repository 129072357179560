import React, { useEffect, useState } from "react";
import Header from "../components/shared/trombinoscope/Header";
import { Spinner } from "react-bootstrap";
import { useAuth } from "react-oidc-context";
import { Group } from "../interfaces/AuthentificationInterface";
import TableView from "../components/shared/trombinoscope/TableView";
import GalleryView from "../components/shared/trombinoscope/GalleryView";
import axios from "axios";
import { toast } from "react-toastify";
import { Student } from "../components/shared/trombinoscope/StudentInterface";
import { PromotionUtils } from "../utils/PromotionUtils";

const DEFAULT_COLUMNS = [
    { key: "name", label: "Nom", visible: true },
    { key: "phone", label: "Téléphone", visible: true },
    { key: "email", label: "Email", visible: true },
    { key: "group", label: "Équipe", visible: true },
    { key: "groupName", label: "Nom", visible: true },
    { key: "pae", label: "PAE", visible: true },
    { key: "cec", label: "CEC", visible: true },
    { key: "international", label: "International", visible: true },
    { key: "linkedin", label: "LinkedIn", visible: true },
];

export default function Trombinoscope() {
    const auth = useAuth();
    const userGroup = auth.user?.profile.group as Array<Group>;
    const isTeacherUser =
    userGroup.includes(Group.MajorLeader) || userGroup.includes(Group.Teacher);
    const [viewMode, setViewMode] = useState("table");
    const [searchTerm, setSearchTerm] = useState("");
    const [columns, setColumns] = useState(DEFAULT_COLUMNS);
    const [students, setStudents] = useState<Student[]>([]);
    const [selectedPromotion, setSelectedPromotion] = useState(
        PromotionUtils.getCurrentPromotion()
    );
    const [sortCriterion, setSortCriterion] = useState<keyof Student | null>(
        null
    );
    const [selectedGroups, setSelectedGroups] = useState<string[]>([]);
    const [selectedGroupsName, setSelectedGroupsName] = useState<string[]>([]);
    const [selectedPAEs, setSelectedPAEs] = useState<string[]>([]);
    const [selectedCECs, setSelectedCECs] = useState<string[]>([]);
    const [selectedInternationals, setSelectedInternationals] = useState<
    string[]
  >([]);

    useEffect(() => {
        const fetchStudents = async () => {
            try {
                const res = isTeacherUser
                    ? await axios.get(
                        `${process.env.REACT_APP_SCOLARITE_API_URL}/student/students`,
                        {
                            headers: {
                                Authorization: `Bearer ${auth.user?.access_token}`,
                            },
                            params: { promotion: selectedPromotion },
                        }
                    )
                    : await axios.get(
                        `${process.env.REACT_APP_SCOLARITE_API_URL}/student/students`,
                        {
                            headers: {
                                Authorization: `Bearer ${auth.user?.access_token}`,
                            },
                            params: { promotion: auth.user?.profile.promotion },
                        }
                    );
                setStudents(res.data);
            } catch (err: any) {
                toast.error(err.message);
            }
        };

        fetchStudents();
    }, [auth.user?.access_token, selectedPromotion, auth.user?.profile.promotion, isTeacherUser]);

    const toggleColumnVisibility = (key: string) => {
        setColumns((prev) =>
            prev.map((col) =>
                col.key === key ? { ...col, visible: !col.visible } : col
            )
        );
    };

    const filters = [
        {
            label: "Équipe",
            options: Array.from(new Set(students.map((s) => s.group.toString()))),
            selectedOptions: selectedGroups,
            onChange: setSelectedGroups,
        },
        {
            label: "Nom",
            options: Array.from(new Set(students.map((s) => s.groupName))),
            selectedOptions: selectedGroupsName,
            onChange: setSelectedGroupsName,
        },
        {
            label: "PAE",
            options: Array.from(new Set(students.map((s) => s.groupPAE))),
            selectedOptions: selectedPAEs,
            onChange: setSelectedPAEs,
        },
        {
            label: "CEC",
            options: Array.from(new Set(students.map((s) => s.groupCEC))),
            selectedOptions: selectedCECs,
            onChange: setSelectedCECs,
        },
        {
            label: "International",
            options: Array.from(new Set(students.map((s) => s.abroadSemester))),
            selectedOptions: selectedInternationals,
            onChange: setSelectedInternationals,
        },
    ];

    if (isTeacherUser) {
        return (
            <div className="d-flex flex-column">
                <Header
                    viewMode={viewMode}
                    setViewMode={setViewMode}
                    searchTerm={searchTerm}
                    setSearchTerm={setSearchTerm}
                    columns={columns}
                    toggleColumnVisibility={toggleColumnVisibility}
                    filters={filters}
                    selectedPromotion={selectedPromotion}
                    setSelectedPromotion={setSelectedPromotion}
                    showPromotionSelector={true}
                    sortCriterion={sortCriterion}
                    setSortCriterion={setSortCriterion}
                />
                <div className="flex-grow-1 mt-2">
                    {viewMode === "table" ? (
                        <TableView
                            students={students}
                            searchTerm={searchTerm}
                            columns={columns}
                            filters={{
                                selectedGroups,
                                selectedGroupsName,
                                selectedPAEs,
                                selectedCECs,
                                selectedInternationals,
                            }}
                            sortCriterion={sortCriterion}
                            selectedPromotion={selectedPromotion.toString()}
                        />
                    ) : (
                        <GalleryView
                            students={students}
                            searchTerm={searchTerm}
                            filters={{
                                selectedGroups,
                                selectedGroupsName,
                                selectedPAEs,
                                selectedCECs,
                                selectedInternationals,
                            }}
                            sortCriterion={sortCriterion}
                        />
                    )}
                </div>
            </div>
        );
    } else if (userGroup.includes(Group.Student)) {
        const studentId = auth.user?.profile.preferred_username;
        if (studentId === undefined) {
            return <Spinner animation="border" variant="primary" />;
        }
        return (
            <div className="d-flex flex-column">
                <Header
                    viewMode={viewMode}
                    setViewMode={setViewMode}
                    searchTerm={searchTerm}
                    setSearchTerm={setSearchTerm}
                    columns={columns}
                    toggleColumnVisibility={toggleColumnVisibility}
                    filters={filters}
                    selectedPromotion={selectedPromotion}
                    setSelectedPromotion={setSelectedPromotion}
                    showPromotionSelector={false}
                    sortCriterion={sortCriterion}
                    setSortCriterion={setSortCriterion}
                />
                <div className="flex-grow-1 mt-2">
                    {viewMode === "table" ? (
                        <TableView
                            students={students}
                            searchTerm={searchTerm}
                            columns={columns}
                            filters={{
                                selectedGroups,
                                selectedGroupsName,
                                selectedPAEs,
                                selectedCECs,
                                selectedInternationals,
                            }}
                            sortCriterion={sortCriterion}
                            selectedPromotion={selectedPromotion.toString()}
                        />
                    ) : (
                        <GalleryView
                            students={students}
                            searchTerm={searchTerm}
                            filters={{
                                selectedGroups,
                                selectedGroupsName,
                                selectedPAEs,
                                selectedCECs,
                                selectedInternationals,
                            }}
                            sortCriterion={sortCriterion}
                        />
                    )}
                </div>
            </div>
        );
    }

    return <Spinner animation="border" variant="primary" />;
}
